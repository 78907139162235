export const paths = {
  // Menu
  menu: {
    root: '/clubs/:clubId/menu',
    editMenu: '/clubs/:clubId/menu/:menuId/edit',
    newMenu: '/clubs/:clubId/menu/new',
    scheduling: '/clubs/:clubId/menu/:menuId/schedulings',
    categories: '/clubs/:clubId/menu/:menuId',
    items: '/clubs/:clubId/menu/:menuId/sections/:sectionId',
    editItem: '/clubs/:clubId/menu/:menuId/sections/:sectionId/items/:itemId',
    newItem: '/clubs/:clubId/menu/:menuId/sections/:sectionId/items/new',
    banner: '/clubs/:clubId/menu/:menuId/banner',
    bannerDetails: '/clubs/:clubId/menu/:menuId/banner/details',
    bannerNew: '/clubs/:clubId/menu/:menuId/banner/new',
    bannerDetailsNew: '/clubs/:clubId/menu/:menuId/banner/new/details',
  },
  // Club Profile
  clubProfile: {
    root: '/clubs/:clubId/profile',
    contacts: '/clubs/:clubId/profile/contacts',
    settings: '/clubs/:clubId/profile/settings',
    plan: '/clubs/:clubId/profile/plan',
    qrcode: '/clubs/:clubId/qrcode',
    alert: '/clubs/:clubId/notice',
  },
  // Club Creation
  clubNew: '/clubs/new',
  clubPayment: '/clubs/:clubId/payment',
  // Qr Code Menu
  qrcodeMenu: {
    root: '/public/qrcodemenu/:clubId/:tableId',
    menu: '/public/menu/:clubId/:tableId/:menuId',
  },
  // Auth
  login: '/login',
  signup: '/signup',
  passwordRecovery: '/password/recovery',
  passwordReset: '/password/reset',
  // Others
  accountDeletion: '/account/delete',
  page401: '/401',
  page404: '/404',
  page500: '/error',
  pageAppUpdate: '/update',
};
